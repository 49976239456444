import { NavLink } from 'react-router-dom';
import logo from '../images/trianguleTransp.png' //'../images/willi3.png';
import { FiMenu, FiX } from "react-icons/fi";
import React, { useState } from "react";
import './Navbar.css'

const Navbar2 = () => {
  // Determines if the "menu icon" was clicked or not. Note that this icon is only visible when the window width is small.
  const [menuClicked, setMenuClicked] = useState(false);

  const toggleMenuClick = () => {
    setMenuClicked(!menuClicked);
  };

  const closeMenu = ()=>{
    toggleMenuClick();
  }

  return (
    <nav className='navbar'>
      <img src={logo} className="navbar__logo" alt="logo" />

      <ul
        className={
          menuClicked ? "navbar__list navbar__list--active" : "navbar__list"
        }
      >
        <li className="navbar__item" >
          <NavLink onClick={closeMenu}
            to='/' className={({ isActive }) => (isActive ? 'navbar__link active' : 'navbar__link')}>
            Home
          </NavLink>
        </li>
        <li className="navbar__item" >
        <NavLink onClick={closeMenu}
          to='/about'
          className={({ isActive }) => (isActive ? 'navbar__link active' : 'navbar__link')}>
          About
        </NavLink>
        </li>
      </ul>
      
      {menuClicked ? (
        <FiX size={35} className={"navbar__menu"} onClick={toggleMenuClick} />
      ) : (
        <FiMenu
          size={35}
          className={"navbar__menu"}
          onClick={toggleMenuClick}
        />
      )}
    </nav>
  );
};

export default Navbar2;