import React from 'react';
import '../pages/About.css'
import { Link } from 'react-router-dom';

function About() {
  document.title = "About Page"

  return (
    <section className='section About'>
      <h2 >Willi3 & The others</h2>
      <p className='About__music'>
      I create music as a way of flowing emotions and rhythms. <br />
I consider myself an asynchronous being and as a "musician" I find it really difficult to keep in timing. <br /> 
So my "digital" music is my response to the universe and the lack of pace I suffer. <br /> 
It never gets out of tune, mistakes, and never gets out of sync. The music, as a result of that, might not be likable but it will be well interpreted.
      </p>

      {/* <Link to='/' className='btn'>
        Back Home
      </Link> */}
    </section>
  );
}

export default About;
