import logo from '../images/wings.png';

const Footer = () => {
  return (
    <div className='footer'>
        <a href='https://en.wikipedia.org/wiki/Atra-Hasis'>
          <img src={logo} className="footer_wing"/>
        </a>
    </div>
  );
};
export default Footer;