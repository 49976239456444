import logo from './images/willi3.png';
import './App.css';
import Main from './pages/Main'
import About from './pages/About'
import SharedLayout from './pages/SharedLayout'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          <Route index element={<Main />} />
          <Route path='about' element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
