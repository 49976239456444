import spotify from '../../images/spotify2.png';
import youtube from '../../images/Youtube.png';
import AppleMusic from '../../images/AppleMusic2.png';
import instagram from '../../images/instagram.png';

const WLinks = () => {

  return (
    <>
      <div className='linksW3'>
        <div className='w3From'>
          <a href='https://open.spotify.com/artist/7ptYwDMLnyfKlJQ76WpQKX?si=WnBoFKQ3ROqOZDZSZPn8_Q' target="_blank" rel="noopener noreferrer">
            <img src={spotify} />
          </a>
        </div>
        <div className='w3From'>
          <a href='https://www.youtube.com/user/williearias' target="_blank" rel="noopener noreferrer">
            <img src={youtube} />
          </a>
        </div>
        <div className='w3From'>
          <a href='https://music.apple.com/us/artist/willi3/1477756353' target="_blank" rel="noopener noreferrer">
            <img src={AppleMusic} />
          </a>
        </div>
        <div className='w3From'>
          <a href='https://www.instagram.com/willi3_ok/' target="_blank" rel="noopener noreferrer">
            <img src={instagram} />
          </a>
        </div>
      </div>
      <script>
        console.log("");
      </script>
    </>
  );
};

export default WLinks;