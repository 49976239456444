import React from 'react';
// import { useLocation } from 'react-router-dom';
import WLinks from '../component/main/WLinks';
import '../pages/Main.css'
import Sketch from "react-p5";
// (()=>{
//   console.log(document);

// })()

let x = 0;
let y = 50;

function Main() {

  const setup = (p5, canvasParentRef) => {
		p5.createCanvas(500, 500).parent(canvasParentRef);
	};

	const draw = (p5) => {
		p5.background(0);
		p5.ellipse(x, y, 70, 70);

    if(x<500) {
      x = x+1;
    } else{
      x = x-1;
    }
    console.log(x);
	};

  // let curLoc = useLocation();
  // console.log(curLoc);
  document.title = "Main Page"

  return (
    <section className='section Main'>
      {/* <Sketch setup={setup} draw={draw} /> */}
      <WLinks />
    </section>
  );
}

export default Main;
